import { IColumnHeader } from 'shared/models/table.model';
import { ESortState } from 'shared/enums/sortState.enum';
import { ESortProductDirections } from 'shared/enums/sortDirections.enum';
import { EProductsTableColumns } from 'enums/productsTableColumns.enum';
import { EProductFilterId } from 'enums/productFilterId.enum';

export const startHeaderColumnsProducts: Array<IColumnHeader> = [
  { title: 'Изображение', field: EProductsTableColumns.Images, cssClass: 'td-image', visible: false },
  { title: 'Название', field: EProductsTableColumns.Name, sortable: true, cssClass: 'td-name' },
];

export const endHeaderColumnsProductsWithPrice: Array<IColumnHeader> = [
  { title: 'Цена', field: EProductsTableColumns.Price, sortable: true, width: '150px' },
  { title: 'Действия', field: EProductsTableColumns.Action, visible: false, width: '156px' },
];

export const endHeaderColumnsProducts: Array<IColumnHeader> = [
  { title: 'Предложения', field: EProductsTableColumns.Offer, width: '150px' },
  { title: 'Действия', field: EProductsTableColumns.Action, visible: false, width: '156px' },
];

export const mapProductsSortDirection = new Map<string, ESortProductDirections>([
  [`${EProductsTableColumns.Name}-${ESortState.Asc}`, ESortProductDirections.AlphaDsc],
  [`${EProductsTableColumns.Name}-${ESortState.Desc}`, ESortProductDirections.AlphaAsc],
  [`${EProductsTableColumns.Price}-${ESortState.Asc}`, ESortProductDirections.PriceDsc],
  [`${EProductsTableColumns.Price}-${ESortState.Desc}`, ESortProductDirections.PriceAsc],
]);

export const MAX_ATTRIBUTES_LISTING_TILE = 5;
export const MAX_ATTRIBUTES_LISTING_TABLE = 5;
export const MAX_FILTERS_LISTING_TABLE_COLUMN = 3;

export const UN_LOGGED_FILTERS_IDS = [
  EProductFilterId.Price,
  EProductFilterId.Brand,
  EProductFilterId.Available,
  EProductFilterId.HasMtrCode,
  EProductFilterId.DirectDelivery,
  EProductFilterId.CatalogId,
];
