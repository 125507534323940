import { IFilterSort } from 'shared/models/filters.model';
import { ESortProductDirections } from 'shared/enums/sortDirections.enum';
import { EFilterTypes } from 'shared/enums/filters.enum';
import { ESortProductFilterId } from 'enums/sortProductFilterId.enum';

export const SORT_FILTER_WITH_PRICE: IFilterSort<ESortProductDirections> = {
  name: 'Sort',
  id: '1',
  type: EFilterTypes.Sort,
  options: [
    {
      id: ESortProductFilterId.Default,
      name: 'По умолчанию',
      sortDirection: ESortProductDirections.Default,
    },
    {
      id: ESortProductFilterId.PriceDesc,
      name: 'Сначала дорогие',
      sortDirection: ESortProductDirections.PriceDsc,
    },
    {
      id: ESortProductFilterId.PriceAsc,
      name: 'Сначала дешевые',
      sortDirection: ESortProductDirections.PriceAsc,
    },
    {
      id: ESortProductFilterId.alphaAsc,
      name: 'По алфавиту от А до Я',
      sortDirection: ESortProductDirections.AlphaAsc,
    },
    {
      id: ESortProductFilterId.alphaDesc,
      name: 'По алфавиту от Я до А',
      sortDirection: ESortProductDirections.AlphaDsc,
    },
  ],
};

export const SORT_FILTER: IFilterSort<ESortProductDirections> = {
  name: 'Sort',
  id: '1',
  type: EFilterTypes.Sort,
  options: [
    {
      id: ESortProductFilterId.Default,
      name: 'По умолчанию',
      sortDirection: ESortProductDirections.Default,
    },
    {
      id: ESortProductFilterId.alphaAsc,
      name: 'По алфавиту от А до Я',
      sortDirection: ESortProductDirections.AlphaAsc,
    },
    {
      id: ESortProductFilterId.alphaDesc,
      name: 'По алфавиту от Я до А',
      sortDirection: ESortProductDirections.AlphaDsc,
    },
  ],
};
